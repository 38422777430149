import gsap from "gsap";
import CustomEase from "gsap/CustomEase";

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector(".sine-wave-svg-container");
  if(container) {
    gsap.registerPlugin(CustomEase)
    gsap.defaults({ease: "sine.inOut", duration: 1})
    gsap.set("g", { y: window.innerHeight / 2 });
    
    const speed = 2.5;
    const lineColor1 = '#ccc'; // white
    const lineColor2 = '#00ff00'; // green
    const lineColor3 = '#323DF9'; // blue
    
    let line1 = new wave({
      container: container,
      id: "wave1",
      amplitude: 100,
      frequency: 10.3,
      strokeColor: lineColor1,
    });
    let line2 = new wave({
      container: container,
      id: "wave2",
      amplitude: 70,
      frequency: 11,
      strokeColor: lineColor2,
    });
    let line3 = new wave({
      container: container,
      id: "wave3",
      amplitude: 50,
      frequency: 10.7,
      strokeColor: lineColor3,
    });
    line1.createLine();
    line2.createLine();
    line3.createLine();
    
    function wave(options) {
      
      // Create Lines
      this.createLine = function(){
        let newLine = document.createElementNS("http://www.w3.org/2000/svg", 'polyline');
        newLine.classList.add("sine-wave");
        newLine.id = options.id;
        newLine.style.stroke = options.strokeColor;
        
        this.container = options.container;
        this.svg = this.container.querySelector("svg");
        this.svg.querySelector("g").appendChild(newLine);
        
        this.containerW = this.container.offsetWidth;
        this.svgW = this.containerW*2;
        this.wave = document.querySelector("#" + options.id);
        this.svg.style.width = this.svgW;
        this.svg.style.left = -((this.svgW-this.containerW)/2 );
        this.width = this.svgW;
        this.sinus = CustomEase.create("sinus", "M0,0 C0.4,0 0.3,.3 0.5,1 0.7,.3 0.6,0 1,0");
    
        this.amplitude = options.amplitude;
        this.frequency = options.frequency;
        this.segments  = 500;
        this.interval  = this.width / this.segments;
    
        this.tweenArray= [];
        this.drawLines();
      };
    
      // Draw Lines
      this.drawLines = function(){
        for (var i = 0; i <= this.segments; i++) {
          this.norm = 1 - i / this.segments;
          this.point = this.wave.points.appendItem(this.svg.createSVGPoint());
          this.point.x = i * this.interval;
          this.point.y = this.amplitude / 2 * this.sinus(this.norm);
          this.tween = gsap.to(this.point, speed, { y: -this.point.y, repeat: -1, yoyo: true }).progress(this.norm * this.frequency); 
          this.tweenArray.push(this.tween);
        }
      }
      
      // Resize
      this.resize = function(){
        let PolyNode = this.container.querySelector("#"+options.id); 
        PolyNode.remove();
        this.createLine();
      }
    };
  }
});
